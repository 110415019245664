export const LabDiamond: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      d="M14.8801 10.8721L11.5257 7.03853C11.4809 6.9873 11.4256 6.94622 11.3636 6.91803C11.3017 6.88983 11.2344 6.87516 11.1663 6.875H4.45752C4.38943 6.87516 4.32217 6.88983 4.26019 6.91803C4.19822 6.94622 4.14297 6.9873 4.09812 7.03853L0.743721 10.8721C0.665466 10.9615 0.623179 11.0767 0.62506 11.1954C0.626942 11.3142 0.672858 11.428 0.753904 11.5149L7.4627 18.7028C7.50754 18.7509 7.56179 18.7892 7.62207 18.8155C7.68235 18.8417 7.74738 18.8552 7.81312 18.8552C7.87885 18.8552 7.94388 18.8417 8.00416 18.8155C8.06445 18.7892 8.11869 18.7509 8.16353 18.7028L14.8723 11.5149C14.953 11.4277 14.9985 11.3137 15 11.195C15.0014 11.0762 14.9587 10.9612 14.8801 10.8721ZM13.4647 10.7086H10.9267L8.77032 7.8334H10.9489L13.4647 10.7086ZM4.61206 11.667L6.41685 16.1793L2.20588 11.667H4.61206ZM9.9791 11.667L7.81192 17.0856L5.64474 11.667H9.9791ZM5.89512 10.7086L7.81192 8.15267L9.72872 10.7086H5.89512ZM11.0118 11.667H13.418L9.20699 16.1793L11.0118 11.667ZM4.67496 7.8334H6.85352L4.69712 10.7086H2.15916L4.67496 7.8334Z"
      fill="currentColor"
    />
    <path
      d="M11.2651 6.14814C11.5286 5.95049 11.7592 5.76931 12.0062 5.59638C12.6568 5.15168 13.3074 4.70698 13.958 4.26228C13.9827 4.24581 14.0321 4.24581 14.0568 4.25403C14.2791 4.33639 14.5015 4.43521 14.7238 4.51755C14.8638 4.73166 17.3426 8.56102 18.4132 10.2163C18.4297 10.241 18.4462 10.274 18.4544 10.2987H18.4708C18.6273 10.1998 18.7755 10.0928 18.932 9.99395C19.1049 9.87866 19.2861 9.7716 19.4673 9.65631V9.63985C19.4673 9.63985 19.4343 9.59044 19.4179 9.56573C18.3473 7.91046 15.8603 4.06463 15.7285 3.85874C15.7367 3.61993 15.7532 3.38934 15.7532 3.15052C15.7532 3.10111 15.7697 3.06817 15.8191 3.04346C16.7249 2.48347 17.6143 1.907 18.5614 1.40465C18.5779 1.39641 18.5943 1.38819 18.6355 1.3717C18.512 1.347 18.4132 1.32229 18.3143 1.30583C17.2355 1.14113 16.2144 1.35524 15.2179 1.75877C15.1026 1.80818 14.9873 1.86584 14.8638 1.907C14.8226 1.92347 14.7732 1.92347 14.732 1.907C14.592 1.85759 14.4603 1.79994 14.3285 1.74231C14.2955 1.73407 14.2461 1.73407 14.2214 1.75055C13.9497 1.92349 13.6861 2.09643 13.4144 2.26937C13.3732 2.29407 13.3567 2.33524 13.3567 2.38465C13.3567 2.52464 13.3567 2.67288 13.3403 2.81289C13.3403 2.85406 13.3073 2.90347 13.2744 2.93642C12.7062 3.43877 12.2038 3.99876 11.825 4.64934C11.5697 5.0858 11.3885 5.54698 11.2732 6.03285C11.2732 6.05756 11.265 6.08226 11.2485 6.13992L11.2651 6.14814Z"
      fill="currentColor"
    />
  </svg>
);
