export const ColourDiamond: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="fancy color diamond">
      <path
        id="Vector"
        d="M19.125 7.8125L14.75 2.8125C14.6875 2.75 14.625 2.75 14.5625 2.6875C14.5 2.6875 14.4375 2.625 14.375 2.625H5.625C5.5625 2.625 5.5 2.625 5.4375 2.6875C5.375 2.6875 5.3125 2.75 5.25 2.8125L0.875 7.8125C0.8125 7.875 0.75 8 0.75 8.125C0.75 8.25 0.8125 8.375 0.875 8.4375L9.625 17.8125C9.6875 17.875 9.75 17.875 9.75 17.9375C9.8125 17.9375 9.875 18 9.9375 18C10 18 10.0625 18 10.125 17.9375C10.1875 17.9375 10.25 17.875 10.25 17.8125L19 8.4375C19.0625 8.375 19.125 8.25 19.125 8.125C19.1875 8 19.1875 7.875 19.125 7.8125ZM5.9375 8.5625L8.625 15.3125L2.3125 8.5625H5.9375ZM13.0625 8.5625L10 16.25L6.9375 8.625H13.0625V8.5625ZM7.1875 7.625L10 3.875L12.8125 7.625H7.1875ZM14.0625 8.5625H17.6875L11.375 15.375L14.0625 8.5625ZM17.6875 7.625H13.9375L10.875 3.5625H14.125L17.6875 7.625ZM5.8125 3.5625H9.0625L6 7.625H2.25L5.8125 3.5625Z"
        fill="url(#paint0_linear_1807_885)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1807_885"
        x1="4.87469"
        y1="-0.89875"
        x2="12.539"
        y2="12.5905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1965" stopColor="#F57C21" />
        <stop offset="0.3972" stopColor="#EC0972" />
        <stop offset="0.6139" stopColor="#8D2790" />
        <stop offset="0.7957" stopColor="#109A95" />
        <stop offset="0.9633" stopColor="#006BB6" />
      </linearGradient>
    </defs>
  </svg>
);
