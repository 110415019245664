export const WeddingClassic: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="40"
    height="16"
    viewBox="0 0 40 16"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-weddingband-classic">
      <path
        id="Vector"
        d="M4.7654 5.01172H35.2395C35.6454 5.01172 36.0337 5.0882 36.4043 5.24704C36.7632 5.40589 37.0809 5.61768 37.3515 5.8883C37.6221 6.15892 37.8339 6.47661 37.9869 6.83546C38.1398 7.19432 38.2222 7.58261 38.2222 8.0003C38.2222 8.41799 38.1457 8.80627 37.9869 9.16514C37.828 9.52401 37.6162 9.84169 37.3515 10.1123C37.0809 10.3829 36.7691 10.6006 36.4043 10.7536C36.0396 10.9124 35.6513 10.9889 35.2395 10.9889H4.7654C4.35947 10.9889 3.97119 10.9124 3.61232 10.7536C3.24757 10.5947 2.924 10.3829 2.65927 10.1123C2.38864 9.84168 2.17685 9.524 2.0239 9.16514C1.87095 8.80629 1.78857 8.41799 1.78857 8.0003C1.78857 7.58261 1.86506 7.19433 2.0239 6.83546C2.18274 6.47659 2.39453 6.15891 2.65927 5.8883C2.92989 5.61768 3.24757 5.40001 3.61232 5.24704C3.97119 5.0882 4.35947 5.01172 4.7654 5.01172ZM35.6748 5.65297C35.6042 5.64121 35.5336 5.62944 35.463 5.62356C35.3924 5.61769 35.316 5.6118 35.2395 5.6118H4.76541C4.43597 5.6118 4.13004 5.67064 3.8359 5.79418C3.54762 5.92361 3.30054 6.09421 3.08287 6.31189C2.86521 6.52957 2.69459 6.78253 2.57105 7.0708C2.44162 7.36495 2.37691 7.67087 2.37691 8.00032C2.37691 8.46508 2.50045 8.88277 2.74165 9.26516C2.98285 9.64755 3.30054 9.93583 3.68294 10.13C3.85354 10.2123 4.03004 10.277 4.21241 10.3241C4.39479 10.3712 4.58893 10.3947 4.79483 10.3947H35.216C35.6101 10.3947 35.9749 10.3123 36.3043 10.1417C36.6338 9.97111 36.9103 9.74169 37.1279 9.4593C37.2868 9.2534 37.4044 9.02984 37.4927 8.77688C37.5809 8.52391 37.628 8.26505 37.628 7.98855C37.628 7.69441 37.5809 7.4179 37.4868 7.15905C37.3868 6.9002 37.2515 6.66487 37.0809 6.45897C36.9103 6.25307 36.7044 6.07658 36.469 5.93539C36.2337 5.80008 35.969 5.70595 35.6807 5.64711L35.6748 5.65297Z"
      />
    </g>
  </svg>
);
