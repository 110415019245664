export const Gemstone: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 1">
      <g id="g102">
        <path
          id="path104"
          d="M5.11851 14.9563H10.881L14.9545 10.8815V5.11966L10.881 1.04551H5.11851L1.04435 5.11966V10.8815L5.11851 14.9563ZM10.9934 15.5H5.00612C4.93392 15.5 4.86422 15.4717 4.81337 15.4209L0.58037 11.1866C0.528886 11.1358 0.5 11.0667 0.5 10.9939V5.00665C0.5 4.93444 0.528886 4.86538 0.58037 4.81452L4.81337 0.580897C4.86422 0.53004 4.93392 0.501158 5.00612 0.501158H10.9934C11.0656 0.501158 11.1346 0.53004 11.1855 0.580897L15.4191 4.81452C15.47 4.86538 15.4988 4.93444 15.4988 5.00665V10.9939C15.4988 11.0667 15.47 11.1358 15.4191 11.1866L11.1855 15.4209C11.1346 15.4717 11.0656 15.5 10.9934 15.5Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g106">
        <path
          id="path108"
          d="M6.13525 12.5017H9.86289L12.4998 9.86488V6.13602L9.86289 3.49977H6.13525L3.49778 6.13602V9.86488L6.13525 12.5017ZM9.97625 13.0459H6.0225C5.95019 13.0459 5.88094 13.0171 5.82947 12.9662L3.03389 10.17C2.98242 10.1192 2.95361 10.0499 2.95361 9.97763V6.02326C2.95361 5.95095 2.98242 5.88232 3.03389 5.83084L5.82947 3.03527C5.88094 2.98441 5.95019 2.9556 6.0225 2.9556H9.97625C10.048 2.9556 10.1172 2.98441 10.1687 3.03527L12.9642 5.83084C13.0151 5.88232 13.0439 5.95095 13.0439 6.02326V9.97763C13.0439 10.0499 13.0151 10.1192 12.9642 10.17L10.1687 12.9662C10.1172 13.0171 10.048 13.0459 9.97625 13.0459Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g110">
        <path
          id="path112"
          d="M6.02244 3.5C5.91567 3.5 5.81455 3.43656 5.77121 3.33167L4.75434 0.877117C4.69655 0.738939 4.7625 0.579408 4.90131 0.521624C5.04137 0.463841 5.19965 0.531046 5.25744 0.669224L6.2743 3.12378C6.33146 3.26195 6.26551 3.42148 6.1267 3.47927C6.09278 3.49371 6.05699 3.5 6.02244 3.5Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g114">
        <path
          id="path116"
          d="M9.97713 3.5C9.94195 3.5 9.90678 3.49309 9.87223 3.47864C9.73343 3.42149 9.66811 3.26195 9.72527 3.12315L10.7421 0.668574C10.7999 0.529766 10.9607 0.463186 11.0976 0.521598C11.2364 0.578754 11.3024 0.738293 11.2452 0.877101L10.2277 3.33167C10.1844 3.43656 10.0839 3.5 9.97713 3.5Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g118">
        <path
          id="path120"
          d="M3.22646 6.29492C3.19154 6.29492 3.15602 6.2889 3.1223 6.27445L0.668086 5.25748C0.5296 5.20028 0.463368 5.04072 0.520568 4.90223C0.578973 4.76254 0.739138 4.69691 0.87642 4.75471L3.33063 5.77168C3.46911 5.82888 3.53535 5.98844 3.47754 6.12693C3.43419 6.2317 3.33304 6.29492 3.22646 6.29492Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g122">
        <path
          id="path124"
          d="M12.7718 6.2959C12.6652 6.2959 12.564 6.2325 12.5207 6.12765C12.4634 5.98927 12.5292 5.82956 12.6682 5.77226L15.1224 4.75546C15.262 4.69694 15.4205 4.7646 15.4778 4.90237C15.5351 5.04136 15.4693 5.20046 15.3303 5.25837L12.8767 6.27517C12.8419 6.28919 12.8066 6.2959 12.7718 6.2959Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g126">
        <path
          id="path128"
          d="M0.772039 11.2656C0.665719 11.2656 0.565117 11.2022 0.521103 11.0976C0.463941 10.9592 0.529676 10.7998 0.668579 10.742L3.12253 9.72569C3.26258 9.66739 3.42091 9.73484 3.47808 9.87317C3.53524 10.0121 3.46893 10.1716 3.3306 10.2287L0.87665 11.245C0.842353 11.2593 0.806908 11.2656 0.772039 11.2656Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g130">
        <path
          id="path132"
          d="M15.2267 11.2666C15.1925 11.2666 15.1565 11.2599 15.1224 11.2459L12.6682 10.2297C12.5292 10.1718 12.4634 10.0127 12.5207 9.87369C12.578 9.73592 12.7377 9.66765 12.8767 9.72678L15.3303 10.743C15.4693 10.8003 15.5351 10.96 15.4778 11.0984C15.4345 11.2032 15.3333 11.2666 15.2267 11.2666Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g134">
        <path
          id="path136"
          d="M5.00593 15.499C4.971 15.499 4.93607 15.4929 4.90169 15.4785C4.76307 15.4214 4.69709 15.2617 4.75475 15.1231L5.77164 12.6685C5.82931 12.5299 5.989 12.4628 6.12706 12.5215C6.26568 12.5786 6.33166 12.7383 6.27455 12.877L5.25766 15.3316C5.21385 15.4358 5.11294 15.499 5.00593 15.499Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
      <g id="g138">
        <path
          id="path140"
          d="M10.993 15.5C10.8862 15.5 10.7852 15.437 10.7415 15.3325L9.72479 12.8778C9.6676 12.7389 9.73296 12.5796 9.87186 12.5224C10.0125 12.464 10.1701 12.5311 10.2273 12.6695L11.2445 15.1241C11.3017 15.2625 11.2358 15.4224 11.0969 15.4796C11.0636 15.4936 11.0274 15.5 10.993 15.5Z"
          fill="#474555"
          stroke="#474555"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);
