export const Princess: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.661308 0.689274L1.60873 2.45296L2.24596 3.63894V14.3916L1.60873 15.578L0.661308 17.3412V0.689274ZM17.0518 0.764505L14.6609 2.15364H3.73366L0.852402 0.479673H17.5418L17.0518 0.764505ZM17.7329 17.3412L16.785 15.578L16.1478 14.3916V3.63894L16.785 2.45296L17.7329 0.689274V17.3412ZM1.20441 17.3461L3.73326 15.8773H14.6609L16.3678 16.8689L17.5418 17.5509H0.851995L1.20441 17.3461ZM0.770733 17.4354L2.35861 14.4793H3.64395V15.7662L2.14177 16.6392L0.770733 17.4354ZM2.38677 4.79716L2.89407 9.01524L2.38677 13.233V4.79716ZM3.64395 2.26427V3.55125H2.35861L0.770733 0.59553L1.90321 1.2529L3.64395 2.26427ZM9.19688 2.82629L4.86494 2.29444H13.5293L9.19688 2.82629ZM16.0351 3.55125H14.7502V2.26427L16.6836 1.14105L17.6231 0.59553L16.0351 3.55125ZM16.007 13.2334L15.5001 9.01524L16.007 4.79755V13.2334ZM14.7502 14.4793H16.0351L17.6231 17.4354L14.7502 15.7662V14.4793ZM9.19688 15.2038L13.5296 15.7365H4.86253L9.19688 15.2038ZM3.01556 9.18305L3.34826 11.9525L3.63469 14.3385H2.39602L3.01556 9.18305ZM3.01556 8.84712L2.73596 6.52305L2.39602 3.69204H3.63511L3.01556 8.84712ZM8.82395 2.92284L3.78477 3.54199V2.3033L5.21775 2.4795L8.82395 2.92284ZM14.6094 2.3033V3.54199L14.4939 3.5279L9.56982 2.92284L13.1704 2.4803L14.6094 2.3033ZM15.3786 8.84749L14.7594 3.69204H15.9981L15.3786 8.84749ZM5.21775 14.6647L8.82395 15.1081L3.78477 15.7272V14.4889L5.21775 14.6647ZM9.19688 3.01857L14.6167 3.6848L15.2567 9.01524L14.6167 14.3461L9.19688 15.0119L3.77792 14.3457L3.13706 9.01524L3.77753 3.6848L4.08326 3.64699L9.19688 3.01857ZM15.9981 14.3385H14.7594L15.3786 9.18343L15.6578 11.5075L15.9981 14.3385ZM14.6094 15.7272L14.5032 15.7139L9.56982 15.1081L14.6094 14.4889V15.7272ZM0.520508 17.6212L0.574014 17.6892L0.590908 17.6916H17.8033L17.8201 17.6892L17.8736 17.6212V0.409267L17.8201 0.341286L17.8033 0.338867H0.590908L0.520508 0.409267V17.6212Z"
      fill="#474555"
      stroke="#474555"
      strokeWidth="0.3"
    />
  </svg>
);
