export const WeddingAlternative: React.FC<React.SVGAttributes<{}>> = (
  props,
) => (
  <svg
    width="40"
    height="16"
    viewBox="0 0 40 16"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic-weddingband-alternative">
      <path
        id="Vector"
        d="M4.7654 5.01172H35.2395C35.6454 5.01172 36.0337 5.0882 36.4043 5.24704C36.7632 5.40589 37.0809 5.61768 37.3515 5.8883C37.6221 6.15892 37.8339 6.47661 37.9869 6.83546C38.1398 7.19432 38.2222 7.58261 38.2222 8.0003C38.2222 8.41799 38.1457 8.80627 37.9869 9.16514C37.828 9.52401 37.6162 9.84169 37.3515 10.1123C37.0809 10.3829 36.7691 10.6006 36.4043 10.7536C36.0396 10.9124 35.6513 10.9889 35.2395 10.9889H4.7654C4.35947 10.9889 3.97119 10.9124 3.61232 10.7536C3.24757 10.5947 2.924 10.3829 2.65927 10.1123C2.38864 9.84168 2.17685 9.524 2.0239 9.16514C1.87095 8.80629 1.78857 8.41799 1.78857 8.0003C1.78857 7.58261 1.86506 7.19433 2.0239 6.83546C2.18274 6.47659 2.39453 6.15891 2.65927 5.8883C2.92989 5.61768 3.24757 5.40001 3.61232 5.24704C3.97119 5.0882 4.35947 5.01172 4.7654 5.01172ZM35.6748 5.65297C35.6042 5.64121 35.5336 5.62944 35.463 5.62356C35.3924 5.61769 35.316 5.6118 35.2395 5.6118H4.76541C4.38302 5.6118 4.03004 5.69416 3.70058 5.86478C3.37112 6.03539 3.09462 6.26482 2.87696 6.5472H37.1456C36.975 6.31777 36.7632 6.12363 36.5102 5.96478C36.2573 5.80594 35.9808 5.70005 35.6748 5.6471V5.65297ZM2.37688 8.0003C2.37688 8.13561 2.38864 8.27093 2.41218 8.40623C2.43572 8.54154 2.47101 8.66508 2.50631 8.78862H37.4927C37.5339 8.65919 37.5633 8.52977 37.5868 8.39446C37.6104 8.25914 37.6221 8.12972 37.6221 7.98853C37.6221 7.84145 37.6104 7.69438 37.5809 7.55319C37.5515 7.41199 37.5162 7.27669 37.4692 7.14726L2.53572 7.1355C2.48278 7.2708 2.44159 7.41199 2.41218 7.55319C2.38277 7.69438 2.37099 7.84146 2.37099 7.99441L2.37688 8.0003ZM2.824 9.37693C2.93578 9.53577 3.05932 9.67697 3.20639 9.8064C3.35346 9.93583 3.51231 10.0417 3.68291 10.1358C3.85352 10.2182 4.03001 10.2829 4.21239 10.33C4.39477 10.377 4.58891 10.4006 4.79481 10.4006H35.2159C35.6101 10.4006 35.9749 10.3182 36.3043 10.1476C36.6337 9.97699 36.9103 9.74756 37.1279 9.46518C37.1397 9.45341 37.1515 9.43577 37.1573 9.42399C37.1632 9.41222 37.175 9.39458 37.1867 9.3828H2.82397L2.824 9.37693Z"
        fill="#474555"
      />
    </g>
  </svg>
);
