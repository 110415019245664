export const MenuMetalColor: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <rect width="20" height="20" rx="5" />{' '}
  </svg>
);
