export const PureHeartDiamond: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="pure heart">
      <path
        id="Vector"
        d="M10.56 10.6844C10.56 10.6844 10.4711 10.7467 10.4178 10.7822C10.3644 10.8177 10.3111 10.8355 10.2578 10.8444L11.7689 16.1867V18L9.99111 16.9067L8.21334 18V16.1867L9.76001 10.8622C9.76001 10.8622 9.69778 10.8356 9.66224 10.8267C9.62668 10.8089 9.59113 10.7911 9.56447 10.7733L6.69336 16.0089L5.41336 17.2889L4.92446 15.2533L2.8889 14.7644L4.1689 13.4844L9.34224 10.5955C9.34224 10.5955 9.26224 10.5066 9.23557 10.4444C9.20001 10.3911 9.18224 10.3289 9.15557 10.2667L3.81334 11.7778H2L3.09333 9.99999L2 8.22222H3.81334L9.13778 9.76889C9.13778 9.76889 9.16445 9.68888 9.18222 9.64445L9.24445 9.52001L4.16888 6.69334L2.88888 5.41334L4.92444 4.92444L5.41334 2.88888L6.69334 4.16888L9.49334 9.26222C9.49334 9.26222 9.58224 9.21778 9.62668 9.19111C9.67111 9.16445 9.72445 9.14668 9.77778 9.13778L8.23111 3.81334V2L10.0089 3.09333L11.7867 2V3.81334L10.2756 9.15557C10.3467 9.17334 10.4178 9.2089 10.4711 9.24447C10.5244 9.28003 10.5867 9.32447 10.6311 9.3778L13.4933 4.17779L14.7733 2.89779L15.2622 4.93336L17.2978 5.42225L16.0178 6.70226L10.8178 9.60893C10.8178 9.60893 10.8356 9.65336 10.8534 9.68893C10.8623 9.71559 10.8711 9.74226 10.8889 9.76893L16.2134 8.24003H18.0267L16.9334 10.0178L18.0267 11.7956H16.2134L10.8889 10.2667C10.8712 10.32 10.8445 10.3644 10.8267 10.4178C10.8089 10.4622 10.7823 10.5067 10.7467 10.5511L16.0267 13.5022L17.3067 14.7822L15.2711 15.2711L14.7822 17.3067L13.5022 16.0267L10.5778 10.7111L10.56 10.6844Z"
        fill="#474555"
      />
    </g>
  </svg>
);
