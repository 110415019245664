export const ColourLabDiamond: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0884 11.0479L11.6745 7.14631C11.6257 7.09754 11.577 7.09754 11.5282 7.04877C11.4794 7.04877 11.4307 7 11.3819 7H4.55407C4.5053 7 4.45653 7 4.40776 7.04877C4.35899 7.04877 4.31022 7.09754 4.26145 7.14631L0.84754 11.0479C0.79877 11.0967 0.75 11.1942 0.75 11.2918C0.75 11.3893 0.79877 11.4869 0.84754 11.5356L7.67536 18.8511C7.72413 18.8999 7.7729 18.8999 7.7729 18.9487C7.82167 18.9487 7.87044 18.9975 7.91921 18.9975C7.96798 18.9975 8.01675 18.9975 8.06552 18.9487C8.11429 18.9487 8.16306 18.8999 8.16306 18.8511L14.9909 11.5356C15.0397 11.4869 15.0884 11.3893 15.0884 11.2918C15.1372 11.1942 15.1372 11.0967 15.0884 11.0479ZM4.79792 11.6332L6.89504 16.9003L1.96925 11.6332H4.79792ZM10.3577 11.6332L7.96798 17.6319L5.57824 11.6819H10.3577V11.6332ZM5.77332 10.9016L7.96798 7.9754L10.1626 10.9016H5.77332ZM11.138 11.6332H13.9667L9.04092 16.9491L11.138 11.6332ZM13.9667 10.9016H11.0405L8.65076 7.73155H11.1868L13.9667 10.9016ZM4.70038 7.73155H7.23643L4.84669 10.9016H1.92048L4.70038 7.73155Z"
      fill="url(#paint0_linear_1748_10377)"
    />
    <path
      d="M11.2651 6.14814C11.5286 5.95049 11.7592 5.76931 12.0062 5.59638C12.6568 5.15168 13.3074 4.70698 13.958 4.26228C13.9827 4.24581 14.0321 4.24581 14.0568 4.25403C14.2791 4.33639 14.5015 4.43521 14.7238 4.51755C14.8638 4.73166 17.3426 8.56102 18.4132 10.2163C18.4297 10.241 18.4462 10.274 18.4544 10.2987H18.4708C18.6273 10.1998 18.7755 10.0928 18.932 9.99395C19.1049 9.87866 19.2861 9.7716 19.4673 9.65631V9.63985C19.4673 9.63985 19.4343 9.59044 19.4179 9.56573C18.3473 7.91046 15.8603 4.06463 15.7285 3.85874C15.7367 3.61993 15.7532 3.38934 15.7532 3.15052C15.7532 3.10111 15.7697 3.06817 15.8191 3.04346C16.7249 2.48347 17.6143 1.907 18.5614 1.40465C18.5779 1.39641 18.5943 1.38819 18.6355 1.3717C18.512 1.347 18.4132 1.32229 18.3143 1.30583C17.2355 1.14113 16.2144 1.35524 15.2179 1.75877C15.1026 1.80818 14.9873 1.86584 14.8638 1.907C14.8226 1.92347 14.7732 1.92347 14.732 1.907C14.592 1.85759 14.4603 1.79994 14.3285 1.74231C14.2955 1.73407 14.2461 1.73407 14.2214 1.75055C13.9497 1.92349 13.6861 2.09643 13.4144 2.26937C13.3732 2.29407 13.3567 2.33524 13.3567 2.38465C13.3567 2.52464 13.3567 2.67288 13.3403 2.81289C13.3403 2.85406 13.3073 2.90347 13.2744 2.93642C12.7062 3.43877 12.2038 3.99876 11.825 4.64934C11.5697 5.0858 11.3885 5.54698 11.2732 6.03285C11.2732 6.05756 11.265 6.08226 11.2485 6.13992L11.2651 6.14814Z"
      fill="url(#paint1_linear_1748_10377)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1748_10377"
        x1="3.96859"
        y1="4.25034"
        x2="9.94922"
        y2="14.7763"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1965" stopColor="#F57C21" />
        <stop offset="0.3972" stopColor="#EC0972" />
        <stop offset="0.6139" stopColor="#8D2790" />
        <stop offset="0.7957" stopColor="#109A95" />
        <stop offset="0.9633" stopColor="#006BB6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1748_10377"
        x1="4.99995"
        y1="-5.625"
        x2="19.3749"
        y2="14.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.41" stopColor="#F57C21" />
        <stop offset="0.505" stopColor="#F1434A" />
        <stop offset="0.6" stopColor="#EC0972" />
        <stop offset="0.78" stopColor="#109A95" />
        <stop offset="0.81" stopColor="#006BB6" />
      </linearGradient>
    </defs>
  </svg>
);
