export const Solitaire: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117 32"
    fill="currentColor"
    {...props}
  >
    <title>ic-engagement-solitaire</title>
    <path d="M48.114 16.125l10.4-10.4 10.4 10.4-10.4 10.4-10.4-10.4zM66.327 16.125l-7.813-7.813-7.813 7.813 7.813 7.813 7.813-7.813zM58.514 1.725c-4.482 0-8.486 2.047-11.126 5.257h-35.502c-5.051 0-9.143 4.091-9.143 9.143s4.091 9.143 9.143 9.143h35.502c2.64 3.21 6.644 5.257 11.126 5.257s8.486-2.047 11.126-5.257h35.502c5.051 0 9.143-4.091 9.143-9.143s-4.091-9.143-9.143-9.143h-35.502c-2.64-3.21-6.644-5.257-11.126-5.257zM58.514 3.554c6.93 0 12.571 5.641 12.571 12.571s-5.641 12.571-12.571 12.571c-6.93 0-12.571-5.641-12.571-12.571s5.641-12.571 12.571-12.571zM44.114 16.125c0 2.67 0.727 5.171 1.993 7.314h-34.221c-4.032 0-7.314-3.282-7.314-7.314s3.282-7.314 7.314-7.314h34.221c-1.266 2.144-1.993 4.644-1.993 7.314zM105.143 23.439h-34.221c1.266-2.143 1.993-4.644 1.993-7.314s-0.726-5.171-1.993-7.314h34.221c4.032 0 7.314 3.282 7.314 7.314s-3.282 7.314-7.314 7.314z"></path>
  </svg>
);
