export const NaturalDiamond: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1033 7.81642L14.7283 2.81642C14.6843 2.76608 14.63 2.72574 14.5691 2.69812C14.5082 2.6705 14.4421 2.65622 14.3752 2.65626H5.62521C5.55863 2.65574 5.4927 2.6694 5.43181 2.69634C5.37092 2.72328 5.31647 2.76288 5.27208 2.81251L0.897084 7.81251C0.8216 7.8997 0.780903 8.01165 0.782777 8.12696C0.78465 8.24226 0.828963 8.35283 0.90724 8.43751L9.65724 17.8125C9.7011 17.8596 9.75419 17.8971 9.8132 17.9228C9.8722 17.9485 9.93586 17.9617 10.0002 17.9617C10.0646 17.9617 10.1282 17.9485 10.1872 17.9228C10.2462 17.8971 10.2993 17.8596 10.3432 17.8125L19.0932 8.43751C19.1704 8.35307 19.214 8.2433 19.2159 8.12889C19.2178 8.01449 19.1777 7.90335 19.1033 7.81642ZM5.93302 8.59376L8.63615 15.3508L2.32912 8.59376H5.93302ZM13.058 8.59376L10.0002 16.2375L6.9424 8.59376H13.058ZM7.18771 7.65626L10.0002 3.90626L12.8127 7.65626H7.18771ZM14.0674 8.59376H17.6713L11.3643 15.3508L14.0674 8.59376ZM17.7174 7.65626H13.9846L10.9377 3.59376H14.1627L17.7174 7.65626ZM5.83771 3.59376H9.06271L6.01583 7.65626H2.28302L5.83771 3.59376Z"
      fill="currentColor"
    />
  </svg>
);
